<template>
        <div :class="`content`">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <!-- <div class="row">
                        <div class="col-lg-12">

                            <div class="breadcrumb-main">
                                <h4 class="text-capitalize breadcrumb-title">{{ t('welcome',{},{locale:getLocal}) }}</h4>
           
                            </div>

                        </div>
                    </div> -->
                    <div class="row mt-5">
                        <div class="col-md-12 p-0 mt-5">
                            <div class="card bg-gradient-red-orange position-relative">
                                <img class="logo-head-left" src="/images/numchok.png" alt="">
                                <div class="card-body mt-5">
                                    <div class="row box-popular mt-5 mb-3">
                                        
                                        <div v-for="e in getCART" class="col-12 col-md-3 my-2 position-relative" :key="e.lottoRefNumber">
                                            
                                            <img class="w-100" :src="e.imageFullRes" alt="">
                                            <p class="text-center m-0">หมายเลข {{e.lottoNumber}} <br> จำนวน {{e.qty}} ใบ</p>
                                            <span @click="deleteLotto(e)" class="delete-x text-center">x</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <CheckOutDetail ref="CheckOutDetail" class="set-center" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <!-- <div class="d-flex justify-content-center"> -->
                                            <CheckOut ref="CheckOutRef" class="set-center" />
                                            <!-- </div> -->
                                        </div>
                                    </div>
                             
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>

import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import SearchLotto from '@/components/SearchLotto.vue';
import ListLotto from '@/components/ListLotto.vue';
import CheckOut from '@/components/CheckOut.vue';
import CheckOutDetail from '@/components/CheckOutDetail.vue';
import { useI18n } from 'vue-i18n'
export default {
  name: 'Lottery',
  components: {
    SearchLotto,
    ListLotto,
    CheckOut,
    CheckOutDetail
  },
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  data: function () {
    return {
        searchNumberData:''
      }
    },
  mounted() {
     document.title = 'Cart'
     this.$store.dispatch('display/setNavName','Cart')
  },
  methods: {
    async deleteLotto(lotto){
        let getStateCart = this.getCART;
        getStateCart =getStateCart.filter(e => e.lottoNumber !==lotto.lottoNumber);
        this.$store.dispatch('cart/setCART',getStateCart);
    }
  },
  computed:{
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    getCART:function(){
      return this.$store.getters['cart/getCART']
    },
  }

}

</script>
<style scoped>
.box-popular{
    border: 3px #FFC700 solid;
    padding: 15px;
}
.box-thin{
    border: 1px lightgray solid;
    padding: 15px;
}
.delete-x{
    cursor: pointer;
    position: absolute;
    top: -9px;
    right: 6px;
    background-color: red;
    color: white;
    padding: 0px 6px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 30px;
}
p{
    color:white;
}
.logo-head-left{
  width: 130px;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(0%,-50%)
}
</style>