<template>
  <div class="row max-w-50 box-popular ">
    <div class="col-12 ">
      <div class="row ">
        <div class="col-12">
          <h3 class="text-center text-black" >รายการลอตเตอรี่</h3>
        </div>
      </div>
      <div class="row ">
        <div class="col-12  py-3">             
            <table class="w-100 ">
              <tr v-for="e in getCART"  :key="e.lottoRefNumber">
                <td class="position-relative pl-4" ><span @click="deleteLotto(e)" class="delete-x">x</span>{{e.lottoNumber}}</td>
                <td class="text-left " >{{e.qty}} ใบ</td>
                <td class="text-right">80 บาท</td>
              </tr>
            </table>
        </div>
        <div class="col-12 py-2">     
          <table class="w-100 t-detail">
              <tr class="border-top">
                <td class="" >ค่าลอตเตอรี่</td>
                <td class="text-center" >{{rottoTotalQty}} ใบ</td>
                <td class="text-right">{{rottoPrice}} บาท</td>
              </tr>
              <tr class="border-top">
                <td class="" >ค่าบริการ</td>
                <td class="text-center" >{{rottoTotalQty}} ใบ</td>
                <td class="text-right">{{rottoService}} บาท</td>
              </tr>
              <tr class="border-top border-bottom">
                <td class="font-weight-bold" >รวมราคา</td>
                <td class="text-center" >{{rottoTotalQty}} ใบ</td>
                <td class="text-right">{{rottoNet}} บาท</td>
              </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as serviceMain  from '@/services/main.service';

export default {
  name: 'CheckOutDetail',
  components: {
  },
  props: [
    "verifyId"
  ],
  data: function () {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    async deleteLotto(lotto){
        let getStateCart = this.getCART;
        getStateCart =getStateCart.filter(e => e.lottoNumber !==lotto.lottoNumber);
        this.$store.dispatch('cart/setCART',getStateCart);
    }
  },
  computed: {
    getCART:function(){
      return this.$store.getters['cart/getCART']
    },
    rottoTotalQty:function(){
      return this.getCART.length;
    },
    rottoPrice:function(){
      return this.getCART.length*80;
    },
    rottoService:function(){
      return this.getCART.length*20;
    },
    rottoNet:function(){
      return this.rottoPrice + this.rottoService;
    },
  },
  watch:{

  }
  
}
</script>

<style scoped lang="scss">
.t-detail{
  td{
    padding: 15px 0px;
  }
}
.delete-x{
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 0px;
    background-color: red;
    color: white;
    padding: 0px 6px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 30px;
}
table{
  color:white;
}

</style>
