<template>
  <div class="row max-w-50">
    <div class="col-12 ">
      <div class="row ">
        <div class="col-12 mb-3 p-0">
            <button @click="checkOutData.deliveryToHome='N'" :class="`btn-tran w-100 text-black ${checkOutData.deliveryToHome=='N'?'active':''}`"> <h2>ฝากเก็บไว้ที่บริษัท</h2> </button>
        </div>
        <!-- <div class="col-12 mb-3 p-0">
            <button @click="checkOutData.deliveryToHome='Y'" :class="`btn-tran w-100 ${checkOutData.deliveryToHome=='Y'?'active':''}`"><h2>ส่งถึงบ้าน</h2></button>
        </div> -->
      </div>
      <div v-if="checkOutData.deliveryToHome" class="row border-box-yellow">
        <div class="col-12 mb-3">
          <h4 class="text-center text-black">หมายเลขโทรศัพท์</h4>
        </div>
        <div class="col-12 mb-3">
          <!-- <input v-model="checkOutData.mobile" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type = "number" maxlength = "10" class="w-100 input-tran"> -->
          <input v-model="checkOutData.mobile" oninput="javascript: if (!/^\d*$/.test(this.value)) this.value = this.value.replace(/[^\d]/g, '');" type="text" maxlength="10" class="w-100 input-tran" placeholder="Enter phone number" />
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-3 mb-3 ">
              <button @click="sendOTP()" class="btn-fill w-100 text-black">ส่ง OTP</button>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input v-model="otpData.OTP" type="text" class="w-100 input-tran">
            </div>
          </div>
        </div>
        <div v-if="otpData.isSendOTP" class="col-12 text-center mb-3">
          <button v-if="otpData.isSuccess !==true" @click="verifyOTP()" class="btn-fill  max-w-50 px-5">ยืนยัน OTP</button>
        </div>
        <div v-if="otpData.isSendOTP" class="col-12 text-center mb-3">
          <button v-if="otpData.isSuccess ==true"  class="btn-fill alert-green max-w-50 px-5" disabled>สำเร็จ</button>
          <button v-if="otpData.isSuccess ==false" class="btn-fill alert-red max-w-50 px-5" disabled>ผิดพลาด</button>
        </div>
        <div class="col-12 mb-3 mb-3">
          <h4 class="text-center text-black">บัตรประจำตัวประชาชน ( 13 หลัก )</h4>
        </div>
        <div class="col-12 mb-3">
          <input v-model="checkOutData.PID" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type = "number" maxlength = "13" class="w-100 input-tran">
        </div>
        <div v-if="checkOutData.deliveryToHome=='Y'" class="col-12">
          <div class="row">
              <div class="col-12 col-md-4 ">
                <div class="h4 pt-3 pr-3 text-center">พื้นที่จัดส่ง *</div>
              </div>
              <div class="col-12 col-md-8 mb-3">
                <input v-model="checkOutData.address.addressLine1" type="text" class="w-100 input-tran">
              </div>
          </div>
          <div class="row">
              <div class="col-12 col-md-4 mb-3 ">
                <div class="h4 pt-3 pr-3 text-center">ที่อยู่ *</div>
              </div>
              <div class="col-12 col-md-8 mb-3">
                <input v-model="checkOutData.address.addressLine2" type="text" class="w-100 input-tran">
              </div>
          </div>
        </div>
        
        <div class="col-12 mb-3">
          <div class="form-group form-check">
            <label class="container-checkbox text-black">เมื่อถูกรางวัล call center จะติดต่อกลับ
              <input @click="checkOutData.callCenterCallBackWhenWin=='Y'?checkOutData.callCenterCallBackWhenWin='N':checkOutData.callCenterCallBackWhenWin='Y'" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="form-group form-check">
            <label class="container-checkbox text-black">เมื่อทำรายการเสร็จ sms จะถูกส่งไปยังเบอร์โทรศัพท์ที่ระบุ
              <input @click="checkOutData.smsAlertWhenCompletedOrder=='Y'?checkOutData.smsAlertWhenCompletedOrder='N':checkOutData.smsAlertWhenCompletedOrder='Y'" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="col-12 text-center mb-3">
          <button @click="verifyOrder" class="btn-fill text-black max-w-50 px-5">ยืนยัน</button>
        </div>
      </div>
    </div>
    <Overlay v-if="showOverlay" @close="showOverlay = false">
        <template v-slot:body>
          <div class="d-flex justify-content-center height-body">
            <div style=" margin:auto 50px; ">
              <div class="row max-vw-50">
                <div class="col-12 ">
                  <div class="d-flex justify-content-center">
                    <img class="max-w-100" src="/images/logo_overlay.png" >
                  </div>
                </div>
                <div class="col-12">
                  <p class="font-success text-center">ทำรายการสำเร็จ</p>
                </div>
                <div class="col-12">
                  <p class="font-overlay text-center">*กรุณารอ sms จากระบบ</p>
                </div>
              </div>
            </div>

          </div>
        </template>
    </Overlay>
  </div>
  
</template>

<script>

import * as serviceMain  from '@/services/main.service';
import Overlay from '@/components/Overlay.vue'
export default {
  name: 'CheckOut',
  components: {
   
    Overlay
  },
  props: [
    "verifyId"
  ],
  data: function () {
    return {
      showOverlay: false,
      checkOutData:{
        verifyId:"",
        mobile: "",
        PID:"",
        callCenterCallBackWhenWin: "",
        smsAlertWhenCompletedOrder: "",
        deliveryToHome : 'N',
        address:{
            addressLine1:"",
            addressLine2:""
        },
        listLotto:[]

      },
      otpData:{
        transactionId:'',
        OTP:'',
        refCode:'',
        isSuccess:null,
        isSendOTP:false,
       
      }
    }
  },
  mounted() {
    
  },
  methods: {
    async sendOTP(){
      try {
        if(this.checkOutData.mobile.length<10){
          throw new Error("กรุณากรอกเบอร์มือถือให้ครบถ้วน")
        }
        let data = {
            mobile:this.checkOutData.mobile
        }
        let getAPI = await this.$call_api('post','lotto/api/v1/sendOTP',data,'auth');
        this.otpData.transactionId = getAPI.data.transactionId;
        this.otpData.refCode = getAPI.data.refCode;
        this.otpData.isSendOTP =true;
        this.otpData.isSuccess =null;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async verifyOTP(){
      try {
        if(this.checkOutData.mobile.length<10){
          throw new Error("กรุณากรอกเบอร์มือถือให้ครบถ้วน")
        }
        let data = {
            transactionId:this.otpData.transactionId,
            OTP:this.otpData.OTP,
        }
        await this.$call_api('post','lotto/api/v1/verifyOTP',data,'auth');
        this.otpData.isSuccess =true;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
        this.otpData.isSuccess =false;
      }
    },
    async verifyOrder(){
      try {
        if(this.otpData.isSuccess!==true){
          throw new Error('กรุณายืนยัน OTP ก่อนครับ');
        }
        this.checkOutData.listLotto = this.getCART;
        let data_verifyOrder = this.checkOutData;
        let getAPI_verifyOrder = await this.$call_api('post','lotto/api/v1/verifyOrder',data_verifyOrder,'auth');
        this.checkOutData.verifyId = getAPI_verifyOrder.data.verifyId;
        let data_confirmOrder = {
            verifyId:this.checkOutData.verifyId
        }
        let getAPI_confirmOrder = await this.$call_api('post','lotto/api/v1/confirmOrder',data_confirmOrder,'auth');
        this.$store.dispatch('cart/setCART',[]);
        this.$store.dispatch('display/openOverlay');
        this.$router.push({ name: 'Lottery'})
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
  },
  computed: {
    getCART:function(){
      return this.$store.getters['cart/getCART']
    },
  },
  watch:{

  }
  
}
</script>

<style scope="this api replaced by slot-scope in 2.5.0+">
  .label-flex{
    min-width: 130px;
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input{
  text-align: center;
}


</style>
